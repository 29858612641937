import { ThemeProvider } from "@emotion/react";
import { BugReportOutlined } from "@mui/icons-material";
import SettingIcon from '@mui/icons-material/Settings';
import { Alert, IconButton, LinearProgress, Snackbar, createTheme } from "@mui/material";
import { TObject, dcolors, dlog, dnetwork, dtime } from "corexxx";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { buildConfig } from "../../version";
import { DHook } from '../common_hooks/DHook';
import { TNotification, loadingStateAtom, notificationState, useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DDialogSimple } from "./DDialogSimple";
import { DFormDialog } from "./DForm";
import { DLayoutTwoCol } from "./DLayoutTwoCol";
import { DSelectInput } from "./DSelectInput";
import { DText } from "./DText";
import { DTextNetwork } from "./DTextNetwork";
export const DAppWrapper = ({ children }: { children: any }) => {
    const appCommand = useDAppCommand()
    const [open, setOpen] = React.useState(false);
    const [notification] = useRecoilState<TNotification | undefined>(notificationState);
    const [loading] = useRecoilState<boolean>(loadingStateAtom);
    const [globalWarn, setGlobalWarn] = useState<TNotification | null>(null)
    React.useEffect(() => {
        if (notification) {
            setOpen(true);
        }
    }, [notification]);
    // GK enabled
    const theme = createTheme({
        typography: {
            fontFamily: ["Source Sans Pro", "Roboto", "Merriweather", "Lato", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
        },
    });

    DHook.useAsyncEffect(async () => {
        try {
            let data1 = await dnetwork.get('https://dipankar08.github.io/grodok_global_notfication.json')
            if (data1.msg && data1.msg.length > 0 && data1.is_active == true) {
                setGlobalWarn(data1 as TNotification)
            }
        } catch (e) {
            dlog.ex(e as Error)
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <DCol style={{ zIndex: 1 /* Note that zindex of maui is 1200+*/ }}>
                {children}
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={(event, reason) => {
                        if (reason !== "clickaway") {
                            setOpen(false);
                        }
                    }}
                >
                    {notification ? (
                        <Alert onClose={() => setOpen(false)} severity={notification.type} sx={{ width: "100%" }} >
                            {notification.msg}
                        </Alert>
                    ) : (
                        <p>none</p>
                    )}
                </Snackbar>
                {loading ? (
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 2001 }} className="loading">
                        <LinearProgress />
                    </div>
                ) : null}

                <IconButton style={{ color: dcolors.pallets.grey500, position: 'fixed', bottom: 2, right: 2, zIndex: 2002 }} onClick={() => appCommand.api.setGlobalSettingVisibility(true)} ><SettingIcon /></IconButton>
                <DDialogSimple
                    open={appCommand.state.globalSettingVisibility}
                    onClose={() => appCommand.api.setGlobalSettingVisibility(false)}
                    dialog_title="Global Settings ( Developer Only)">
                    <DLayoutTwoCol elements={[
                        [
                            <DText>Setting backend endpoint</DText>,
                            <DSelectInput
                                value={appCommand.state.simpleStoreEndpoint}
                                onChange={appCommand.api.setSimpleStoreEndpoint}
                                options={[{
                                    text: 'localhost',
                                    value: 'http://localhost'
                                },
                                {
                                    text: 'simplestore-main',
                                    value: 'https://simplestore.dipankar.co.in'
                                },
                                {
                                    text: 'grodok-main',
                                    value: 'https://simplestore.grodok.com'
                                }
                                ]} />
                        ],
                        [
                            <DText>Backend Used</DText>,
                            <DText>{appCommand.state.simpleStoreEndpoint}</DText>,
                        ],
                        [
                            <DText>Last UI Build:</DText>,
                            <DText>{buildConfig.date}</DText>,
                        ],
                        [
                            <DText>Backend Version :</DText>,
                            <DTextNetwork url={`${appCommand.state.simpleStoreEndpoint}/info`} getText={(obj) => {
                                return `Version:${obj.version}, build on:${dtime.getFormattedDate(obj.release_date)}`
                            }} />,
                        ],
                        [
                            <DText>Report bugs and issues:</DText>,
                            <DFormDialog
                                model={{
                                    data: [
                                        { type: "input", name: "title", placeholder: "Please enter the title" },
                                        { type: "textarea", name: "description", placeholder: "Please enter the description" },
                                    ],
                                }}
                                onSubmit={async (obj: TObject) => {
                                    await dnetwork.postSimpleStore("https://simplestore.dipankar.co.in/api/remote_feedback/create", obj);
                                    return "feedback submitted";
                                }}
                                dialogConfig={{
                                    title: "Please provide feedback for this website",
                                    customTrigger: <BugReportOutlined style={{ marginLeft: 10, cursor: "pointer" }} />,
                                }}
                            />
                        ],
                    ]}
                        style={{ margin: 10 }} />
                </DDialogSimple>
                <DDialogSimple
                    open={appCommand.state.informationDialog != undefined}
                    dialog_title={appCommand.state.informationDialog?.title || ''}
                    onClose={() => appCommand.api.hideInformationDialog()}
                >
                    <DText style={{ padding: 20, fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>{appCommand.state.informationDialog?.body || ''}</DText>
                </DDialogSimple>

                {globalWarn ? <Alert
                    onClose={() => setGlobalWarn(null)}
                    severity={globalWarn.type}
                    sx={{ width: "100%", position: 'fixed', left: 0, right: 0, top: 0, zIndex: 2002, borderRadius: 0, background: globalWarn.type == 'error' ? dcolors.pallets.red800 : dcolors.pallets.yellow800, color: 'white' }} >{globalWarn.msg}</Alert> : null}
            </DCol>
        </ThemeProvider>
    );
};


